<template>
  <div>
    <v-card flat>
      <v-card-text class="pl-15 pr-15 pt-10">

        <v-text-field v-model="form.current_password"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :error-messages="errors.current_password"
                      :type="show1 ? 'text' : 'password'"
                      dense
                      label="Mot de passe actuel *"
                      outlined
                      @click:append="show1 = !show1"/>

        <v-text-field v-model="form.new_password"
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :error-messages="errors.new_password"
                      :type="show2 ? 'text' : 'password'"
                      dense
                      label="Nouveau mot de passe *"
                      outlined
                      @click:append="show2 = !show2"/>

        <v-text-field v-model="form.confirm_password"
                      :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                      :error-messages="errors.confirm_password"
                      :type="show3 ? 'text' : 'password'"
                      dense
                      label="Confirmer le mot de passe *"
                      outlined
                      @click:append="show3 = !show3"/>

      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn :loading="btnLoading" color="secondary" depressed @click="save">
          <v-icon left>mdi-content-save</v-icon>
          Modifier
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            errors: {},
            form: {},
            btnLoading: false,
            show1: false,
            show2: false,
            show3: false,
        }
    },
    methods: {
        save() {
            this.btnLoading = true
            this.errors = {}
            HTTP.post('/update-password', this.form).then(() => {
                this.btnLoading = false
                this.$successMessage = 'Votre mot de passe a été modifié avec succès !'
                this.form = {}
            }).catch(err => {
                this.errors = err.response.data.errors
                this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
                this.btnLoading = false
                console.log(err)
            })
        }
    }
}
</script>

<style scoped>

</style>